var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drafts-list"},[(_vm.$store.getters.activeBoard)?_c('post-type-picker',{attrs:{"drafts-only":_vm.showDraftsOnly,"active-board-id":_vm.$store.getters.activeBoard.board,"active-permissions":_vm.$store.getters.activeBoard.permissions},on:{"post-type-select":_vm.postTypeSelectHandler,"create-template-button-click":_vm.handleTemplateButtonClick},model:{value:(_vm.isPostTypeModalOpen),callback:function ($$v) {_vm.isPostTypeModalOpen=$$v},expression:"isPostTypeModalOpen"}},[(_vm.showDraftsOnly)?_c('div',{staticClass:"text-center btn-wrap",attrs:{"slot":"custom-data"},slot:"custom-data"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.createFromDraft}},[_vm._v(" "+_vm._s(_vm.$t('create_post_by_draft'))+" ")])],1):_vm._e()]):_vm._e(),_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.goToCreatePost}},[_vm._v(" "+_vm._s(_vm.$t('create_new_post_button'))+" ")])]},proxy:true}])}),_c('a-drawer',{staticClass:"draft-preview-drawer",attrs:{"placement":"right","width":_vm.drawerWidth,"visible":_vm.isActive,"title":_vm.$t('planner_page_post_preview')},on:{"close":_vm.onClose}},[(_vm.selectedDraft)?_c('div',[([ _vm.PostType.Post, _vm.PostType.Paid ].includes(_vm.selectedDraft.type))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args':{
              'model': _vm.selectedDraft,
              'key':'variants',
              'prefix': 'post_',
              'validation': 'required',
              'availableButtonsTypes': _vm.getNewDefaultButtons(),
              'targetToUpload': {
                'target': 'target',
                'target_id': _vm.$store.getters.activeBoard.board
              },
              'disabled': true,
              'base-api-url': 'api.presscode.app/cabinet',
              'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
            }
          }}}):_c('Quizes',{attrs:{"disabled":true},model:{value:(_vm.selectedDraft),callback:function ($$v) {_vm.selectedDraft=$$v},expression:"selectedDraft"}})],1):_vm._e()]),(_vm.draftPosts.length)?_c('a-row',{attrs:{"gutter":[16,16],"type":"flex"}},_vm._l((_vm.draftPosts),function(draft,index){return _c('a-col',{key:index,attrs:{"xs":24,"sm":12,"md":24,"lg":12,"xxl":8}},[_c('a-card',{attrs:{"title":_vm.draftTitle(index)},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a-icon',{staticClass:"mr-1",attrs:{"type":"eye"},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelect(draft)}}}),_c('a-icon',{attrs:{"type":"plus"},on:{"click":function($event){return _vm.createPostFromDraft(draft)}}}),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteDraftClickHandler(draft.key)}}})]},proxy:true}],null,true)})],1)}),1):_c('div',[_c('empty-data')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }